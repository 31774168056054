::-webkit-scrollbar-corner { display: none; }

body {
  background-color: #1D1E3D;
}

.App {
  text-align: center;
  background-color: #1D1E3D;
  overflow: hidden;
}

.App-logo {
  pointer-events: none;
  width: 254px;
}

.questionIcon{
  padding-top: 15px !important;
}

#toolTipStakes, #toolTipPool1, #toolTipPool2, #toolTipPool3, #toolTipSupply, #toolTipFavorBuy, #toolTipFavorMeter, #toolTipActivity, #toolTipSports{
  visibility: hidden;
    color: white;
  text-align: left;
  line-height: 1;
  position: absolute;
  padding: 0px 15px;
  padding-top: 10px !important;
    background-color: rgba(20, 20, 20, 0.9);
    border-radius: 10px;
    font-size: 14px;
    width: 150px;
  left: 0;
  top: 0;
  z-index: 999 !important;
}

#toolTipActivity{
  width: 100px !important;
}

.toolTip h3{
  padding: 0 !important;
  margin: 0 !important;
}

.pool-grid-container{
  display: flex;
  width: 100%;
}

.pool-flex-container{
  width: 30vw;
  margin: 0 !important;
  padding: 0 !important;
  display: grid;
}

.favor-grid-container{
  width:30vw;
}

.stakesCard{
  width: 90vw !important;
}

@media (max-width: 1200px){
   .App-logo{
    width: 137px;
  }

  .activityText{
  width: 100px;
}

  .Favor-Input-Button{
    width: 25% !important;
  }

  .App-logo-div{
    margin-left: 5px !important;
  }

  .stake-info-label, .user-stake-info-label{
    font-size: 14px;
  }

  .favor-grid-container{
    display: grid !important;
    justify-content: center;
    width: 100% !important;
  }
  .activityCard, .pool3Card, .favorBuyCard, .favorMeterCard, .supplyCard, .poolsCard, .stakesCard{
    width: 90vw !important;
  }
  .pool-flex-container{
    width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    display: grid;
  }
  .supply-grid-container, .pool-grid-container{
    display: grid !important;
    justify-content: center !important;
    width: 100% !important;
}
.supply-info{
  width: 100% !important;
  justify-content: center !important;
}
.supply-chart-container{
  width: 100% !important;
  padding: 0 !important;
}
.supply-info-container{
  width: 100% !important;
}
.Buy-Box-Input{
  font-size: 14px !important;
}

.parent-grid-container{
  display: inline-block !important;
  justify-content: center;
  width: 100% !important;
}

.stake-info-label, .user-stake-info-label{
  font-size: 14px !important;
}

.parent-grid-container{
  display: inline-block !important;
  justify-content: center;
  width: 100% !important;
}
}

.supply-ring{
  width: 100% !important;
  height: 100% !important;
}

.Favor-Input-Button{
  width: 25% !important;
}


.supply-ring{
  width: 100% !important;
  height: 100% !important;
}


.supply-grid-container{
  display: flex;
  width: 65vw;
  justify-content: center;
}


#docsList{
  display: grid;
  justify-content: right;
  margin: 5px auto;
  margin-bottom: 0px !important;
}

@media(min-width: 1201px){
  .pool-flex-container{
    display: revert !important;
  }
}

@media(max-width:1350px) and (min-width: 950px){
  #party-balance{
    margin-top: 5px !important;
  }
  #favor-balance{
    margin-top: 5px !important;
  }
}

@media(max-width: 1350px){
#party-balance{
  width: 128px;
  height: 27px;
  display: grid;
  align-items: center;
  text-align: center;
  font-size: 14px !important;
  margin: 0;
}

.parent-container-header{
  display: flex !important;
}

#favor-balance{
  width: 128px;
  height: 27px;
  display: grid;
  align-items: center;
  text-align: center;
  font-size: 14px !important;
  margin: 0;
}
#mobileButton-div{
    display: grid;
  justify-content: right;
  width: 300px;
}
 .mobileDiv{
display: flex;
width: 100vw;
}

.mobileConnect{
  padding-top: 7px !important;
  display: grid;
  margin-left: 60vw;
  width: 29vw !important;
  justify-content: right;
}
.mobileBalance{
  display: flex;
  justify-content: right;
  width: 250px !important;
  margin-bottom: 25px;
}
}

@media(max-width: 949px){

  html, body {
  overflow-x: hidden;
}
body {
  position: relative
}

  #docsList{
    margin: 5px 0px !important;
  }
  #favor-balance{
    margin-top: 0px !important;
  }
  #party-balance{
    margin-top: 0px !important;
  }
  #docsLinks{
    margin-left: 79px !important;
}
.button-container{
  display: flex !important;
  justify-content: center;
  width: 100vw !important;
}
.stakes-contribute-button{
  width: 20vw !important;
  font-size: 12px !important;
  line-height: 1;
}
.stakes-unstake-button{
  width: 20vw !important;
  font-size: 12px !important;
}
.stakes-jump-button{
  width: 20vw !important;
  font-size: 12px !important;
}
.stakes-unstake-button{
  font-size: 12px !important;
  width: 20vw !important;
  line-height: 1;
}
}

@media(max-width: 320px){
  .mobileConnect{
    margin-left: 66vw;
  }
  .App-logo-div img{
    width: 100px;
  }
  .mobileBalance{
    display: grid;
  }
  .Buy-Box-Text{
    font-size: 12px !important;
  }
  .stakes-contribute-button{
  font-size: 10px !important;
  line-height: 1;
}
.stakes-unstake-button{
  font-size: 10px !important;
}
.stakes-jump-button{
  font-size: 10px !important;
}
.stakes-unstake-button{
  font-size: 10px !important;
}
}


.docs-button{
  background: linear-gradient(267.98deg, #5c99f5 23.46%, #397bde 82.64%) !important;
  display: grid;
  justify-content: center;
  cursor: default !important;
  width: 162px !important;
}

#docsList:hover > #docsLinks{
 visibility: visible;
}

#docsLinks{
  list-style-type: none;
  background: rgba(20,20,20,0.8);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 150px;
  margin-left: 0;
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 37px auto;
  z-index: 21;
  padding: 5px;
  position: absolute;
  visibility: hidden;
}

#docsLinks li{
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}


#docsLinks li > a{
text-decoration: none;
font-size: 1rem;
color: white;
text-align: center;
}

#docsLinks li > a:hover{
color: #CA69F7;
}




.App-logo-div {
  display: grid;
  width: 20vw;
  justify-content: left;
}

.App-header {
  display: flex;
  flex-wrap: wrap;
  font-size: calc(10px + 2vmin);
  color: white;
  padding-right: 1rem;
  justify-content: space-between;
  align-items: center;
}

#party-balance {
padding: 5px 17px;
gap: 10px;
display: grid;
height: 27px;
align-items: center;
background: #393A77;
border-radius: 8px;
font-size: 16px;
margin-right: 10px;

}

#favor-balance {
  margin-right: 10px;
  padding: 5px 17px;
  gap: 10px;
  display: grid;
  align-items: center;
  height: 27px;
  border-radius: 8px;
  font-size: 16px;
  background: #396877;
}

@media(min-width: 1351px){
  #favor-balance{
    margin-top: 5px !important;
  }
  #party-balance{
    margin-top: 5px !important;
  }
   .stakes-info{width: 150px}
}


.stakes-parent-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}


@media(max-width: 1350px){
  .stakes-info{display: flex !important; justify-content: space-around !important; width: 100%;}
  .stakes-card-item-content{gap: 0px !important; justify-content: center !important; width: 65vw !important; display: flex !important;}
  .stakes-card-item{display: grid !important; height: 140px !important;}
  .button-container{justify-content: initial !important;}
  .stakes-card{width: 100%; margin-left: 0px !important; display: grid; justify-content: center !important;}
  .epochContainer{display:flex !important;}
}


.parent-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 90%;
}

.bet-info{
  display: grid;
}

.yourBet{
  text-align: left;
}





.pointer{
  cursor: pointer;
}

.bet-info-container{
  background-color: rgba(255, 255, 255, 0.3);
  padding: 15px;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.upcoming-info-container{
    background-color: rgba(255, 255, 255, 0.3);
  padding: 15px;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.team-info-container{
    display: flex;
  justify-content: space-between;
}

.team-div{
  width: 100%;
  justify-content: center;
  display: grid;
}

.bets-image{
  width: 60px;
}

.upcomingSport{
  text-align: left;
  opacity: 0.6;
}

.opacity{
  opacity: 0.6;
}

#stakeText{
  border: 1px solid rgba(255,255,255,0.8);
}

#stakeText, #unstakeText{
  padding: 5px;
  border-radius: 20px;
}

.team-image{
  width: 100px;
  border-radius: 20px;
  margin: 0px 25px;
  padding: 25px;
  border: 3px solid rgba(255,255,255,0.3);
}

.team-pool{
  border: 3px solid rgba(255,255,255,0.3);
  border-radius: 20px;
  width: 150px;
  margin: 0 auto;
  margin-top: 15px;
  font-size: 12px;
}

#team1, #teampool1{
  border: 3px solid rgba(89, 244, 93, 0.7);
}

.bets-text{
  padding: 0;
  margin: 0;
}

#ratioTeam2{
  color: rgba(255, 255, 255, 0.3);
}

.bets-left{
  text-align: left;
}

.bets-right{
  text-align: right;
}

.parent-container-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2px;
  margin: 25px 0px;
}


.child {
  grid-column: span 1;
  grid-row: span 1;
}

.connect-button {
  text-align: center;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 1000;
  font-size: 14px;
  color: #FFFFFF;
  padding: 8px 16px;
  gap: 16px;
  width: 162px;
  height: 37px;
  background: linear-gradient(267.98deg, #23CE91 23.46%, #23AD4A 82.64%);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  cursor: pointer;
  margin-right: 60px;
}

.connected-button {
  text-align: center;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 1000;
  font-size: 14px;
  color: #FFFFFF;
  padding: 8px 16px;
  gap: 16px;
  width: 162px;
  height: 37px;
  background: linear-gradient(267.98deg, #23CE91 23.46%, #23AD4A 82.64%);
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  cursor: pointer;
  margin-right: 60px;
}


/*#region Footer  */

.footer {
  margin-top: -1px !important;
  display: flex;
  flex-direction: row;
  color: white;
  padding: 0px 61px;
  align-items: center;
  justify-content: space-between;
  background: #43447E;
  height: 84px;
}

.music-bar{
  z-index: 999;
  width: 0%;
  display: grid;
  justify-content: right;
  right: 0;
  top: 15%;
  position: fixed;
}

.footer-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

@media(max-width: 600px){
  .footer-left{display: grid !important; gap: 10px !important; margin-right: 20px;}
  .footer{display: flex; justify-content: center;}
  .footer-button:first-child{margin-top: 0px;}
  .footer-button{width: 50vw !important;}
}

.footer-sound-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.footer-button {  
  width: 145px;
  height: 31px;
  background: linear-gradient(267.98deg, #0BA788 23.46%, #0AC075 82.64%);
  border-radius: 8px;

  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  cursor: pointer;
  border: none;
}

.footer-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.footer-icon {
  cursor: pointer;
}

/*#endregion  */


/*#region Mobile Menu  */
.hamburger {
  cursor: pointer;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: #43447E;
  height: 100vh;
  width: 75vw;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}


.close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

/*#endregion  */

/* Media query for mobile devices */
@media screen and (max-width: 1440px) {
  .parent-container {
    grid-template-columns: repeat(2, 1fr); /* Change to a single column layout for smaller screens */
  }
}


/* Media query for mobile devices */
@media screen and (max-width: 950px) {
  .parent-grid-container {
    grid-template-columns: repeat(1, 1fr); /* Change to a single column layout for smaller screens */
  }
  .parent-container-header {
    grid-template-columns: repeat(1, 1fr); /* Change to a single column layout for smaller screens */
  }
}

button {
  color: #ffffff !important;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
