.music-container {
  display: flex;
  height: 142px !important;
  justify-content: center;
  background-color: #43447E;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}

#musicClose{
  background-color:#43447E;
  border: none;
  height: 100%;
  width:10%;
}


.musicCover {
  width: 100px;
  height: 100px;
}

.music-info{
  display:flex;
  justify-content: center;
  align-items: center;
}

.music-text{
  text-align: left;
  color: white !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 1 !important;
}

.music-scrub{
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  display: grid;
}

.music-artist{
  text-align: left;
  margin: 0 !important;
  padding: 0 !important;
  padding-left: 10px !important;
  font-size: 0.8rem;
  line-height: 1;
}

.music-title{
  font-size: 1rem;
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;
  padding-right: 5px !important;
  line-height: 1;
}

.playButton {
  background: none;
  border: none;
  align-items: center;
  justify-content: center;
}

.timeline {
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  background-color: linear-gradient(267.98deg, #5c99f5 23.46%, #397bde 82.64%) !important;
}

